// NavbarLinks.js

import React from "react"
/* import styled from "styled-components" */
import styled from '@emotion/styled'
//import { Link } from "gatsby"
import { injectIntl,  FormattedMessage, Link } from "gatsby-plugin-intl"

const NavItem = styled(Link)`
  text-decoration: none;
  color: white;
  @media (max-width: 768px) {
    color: black;
  }
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: limegreen;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: limegreen;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`


//const NavbarLinks = () => {
const NavbarLinks = ({ children, intl }) => {
  return (
    <>      
      <NavItem to="/posts/"> <FormattedMessage id="Navigation.BLOG" defaultMessage="Blog"/> </NavItem>
      <NavItem to="/"> <FormattedMessage id="Navigation.Praxis" defaultMessage="Praxis"/></NavItem>
      <NavItem to="/"><FormattedMessage id="Navigation.Leistungen" defaultMessage="Leistungen"/></NavItem>
      <NavItem to="/contact/"><FormattedMessage id="Navigation.Kontakt" defaultMessage="Kontakt"/> </NavItem>
      <NavItem to="/legal/impressum/"><FormattedMessage id="Navigation.Impressum" defaultMessage="Impressum"/> </NavItem>
      <NavItem to="/legal/datenschutz/"><FormattedMessage id="Navigation.Datenschutz" defaultMessage="Datenschutz"/></NavItem>
    </>
  )
}

//export default NavbarLinks
export default injectIntl(NavbarLinks)