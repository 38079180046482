import React from "react"
import Navbar from "./navbar/Navbar"


const Header = ({ children }) => {

  return (
      <>
        <header>
            <Navbar />
        </header>
      </>
  )
}
  
export default Header