// Navbar.js

import { React, useState } from "react"
import styled from '@emotion/styled'
import NavbarLinks  from "./NavbarLinks"
import Label from "./label"
import Headroom from "react-headroom"
//import Social from "./social"

/* border-bottom: 2px solid #33333320;*/

const Navigation = styled.nav`
  height: 5vh;
  display: flex;  
  background-color:   #9ae6b4;
  position: relative;
  justify-content: space-between;
  text-transform: uppercase;
  border-bottom: 2px solid pink;
  margin: 0 auto;
  padding: 0 5vw;
  z-index: 2;
  align-self: center;

  @media (max-width: 768px) {
    position: sticky;
    height: 8vh;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }
`




const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;

  @media (max-width: 768px) {
    display: flex;
  }
`


const Navbox = styled.div`
display: flex;
height: 100%;
justify-content: space-around;
align-items: center;
transition: left 0.3s;
overflow: hidden;
@media (max-width: 768px) {
  flex-direction: column;
  position: fixed;
  width: 100vw;  
  justify-content: flex-start;
  padding-top: 10vh;
  background-color: var(--bg);
  top: 8vh;
  left: ${props => (props.open ? "-100%" : "0")};
  height: 92vh;
  background: white; /*linear-gradient(190deg, var(--bg) 70%, var(--textNormal) 70%);*/
}
`
/* Farbe des Hamburges wird über die Property background-Color eingestellt */
const Hamburger = styled.div`  
  background-color: white;
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: white;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`
const Navbar = ({ data }) => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <Headroom
    onUnpin={() => {
       setNavbarOpen(false);
    }}
>
    <Navigation>
      <Label />
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        <Navbox>          
          <NavbarLinks />
        </Navbox>
      ) : (
        <Navbox open>
          <NavbarLinks />
        </Navbox>
      )} 
         
    </Navigation>
    </Headroom>
  )
}

export default Navbar