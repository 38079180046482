// Logo.js
import React from "react"
import styled from '@emotion/styled'
//import { Link  } from "gatsby"
//import { IoIosInfinite } from 'react-icons/io'
import { css } from "@emotion/core"
import Shineing from "../shine/shine"

import { injectIntl, FormattedHTMLMessage,Link } from "gatsby-plugin-intl"

const LabelWrap = styled.div`
  text-decoration: none;
  left: 0;
  color: white;
  margin: auto 0;
  flex: 0 1 32px;  
  font-size: 14px;
  border-radius="100%";

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: limegreen;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: limegreen;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) and (orientation: landscape) {
    flex: 0 1 25px;
  }
`
/*
const CircleWithLogo = styled.circle` 
  width: 39.6px;
  height: 39.6px;
  border-radius: 50%;
  //text-align: center;
  text-align: center;
  font-size: 40.6px;
  border: 2px solid pink;
  color:white;
  background:transparent;
`



      <LabelWrap as={Link} to="/" >  
      <CircleWithLogo css={css`
      font-size: 32px;
        text-align: center;
        display: inline-block;
          `}>             
            <IoIosInfinite   />            
      </CircleWithLogo>
    </LabelWrap>
    
    <IoIosInfinite   />            
*/
//const Label = () => {
const Label = ({ children, intl }) => {

  return (
    <>
      <LabelWrap as={Link} to="/" > 
         HOME
      </LabelWrap>
    
    </>
  )
}

//export default Label
export default injectIntl(Label)