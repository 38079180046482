import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import SchemaOrg from "./SchemaOrg"
import favicon from '../../images/icon.png';

function SEO({ description, lang, meta, title, created, pathname,  og_type, isBlogPost, image, norobots }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            basic_url
            creator
            description
            geo_placename
            geo_position
            geo_region
            google_site_verification
            icbm_pos
            publisher
            msval
            site_name
            title
            zipcode
            image
            organization {
              name
              url
              logo
            }
            social {
              twitter
              fbAppID
            }  
          }
        
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const datePublished = isBlogPost ? {created} : false;
  return (
    <React.Fragment>
    <Helmet      
        htmlAttributes={{
          lang,
        }}

        link={[
        {
            rel: 'shortcut icon',
            type: 'image/png',
            href: `${favicon}`,
         },
        ]}
        title={title}
        pathname={pathname}
        created={created}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        meta={[
          { name: 'robots', content: 'index, follow' },
          { name: 'google.site.verification', content: site.siteMetadata.google_site_verification },
          { name: 'msvalidate.01', content: site.siteMetadata.msval },        
          { name: 'geo.region', content: site.siteMetadata.geo_region },
          { name: 'geo.placename', content: site.siteMetadata.geo_placename },
          { name: 'zipcode', content: site.siteMetadata.zipcode },
          { name: 'geo.position', content: site.siteMetadata.geo_position },
          { name: 'ICBM', content: site.siteMetadata.icbm_pos },
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {             
            property: 'og:url',
            content: pathname ? site.siteMetadata.basic_url + pathname : site.siteMetadata.basic_url,
          },
          {
              property: 'og:image:secure_url',
              content: pathname ? site.siteMetadata.basic_url + pathname : site.siteMetadata.basic_url,
          },
          ,
          {
              property: 'og:image',
              content: image,
          },         
          { property: 'og:site_name', content: site.siteMetadata.title},
          {
            property: `og:type`,
            content: og_type ? og_type : `website`,
          },  
          {
            property: 'article:author',
            content: site.siteMetadata.author,
          },
          {
            property: 'article:published_time',
            content: created,
          },
          /*
          {
            property: 'og:image',
            content: props.thumbnail && props.thumbnail,
          },

          {
            property: 'og:image:width',
            content: '1200',
          },
          {
              property: 'og:image:height',
              content: '630',
          },
          {
              property: 'og:locale',
              content: 'en',
          },
          */
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          /*
          { name: 'twitter:card', content: 'summary_large_image' },
          {
            name: 'twitter:image',
            content: props.thumbnail && props.thumbnail,
          },
          */
        ].concat(meta)}      
      /> 
        <SchemaOrg
        isBlogPost={isBlogPost}
        url={pathname}
        title={title}
        image={image}
        description={description}
        datePublished={datePublished}
        siteUrl={site.siteMetadata.siteUrl}
        author={site.siteMetadata.author}
        organization={site.siteMetadata.organization}
        defaultTitle={site.siteMetadata.title}
      />        
    </React.Fragment>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,  
}

export default SEO