import { graphql, useStaticQuery } from "gatsby"



/*
        graphql`
          query {            
                banner_img: file(relativePath: { regex: "/header/" }) {
                            childImageSharp {
                              # Specify the image processing specifications right in the query.
                              # Makes it trivial to update as your page's design changes.
                              fluid {
                                ...GatsbyImageSharpFluid_withWebp
                              }
                            }
                        }           
            
          }
*/

const useSiteBannerImg= () => {
  const site = useStaticQuery(
    graphql`
      query{
    banner_img: file(relativePath: {eq: "hautarzt_kövari_logo.png"}) {
      childImageSharp {
        fluid {
          base64
                  tracedSVG
                  aspectRatio              
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  originalName
        }
      }
    }
    }
        `    
  )
  return site.banner_img
}

export default useSiteBannerImg