import React from "react"
import { injectIntl, FormattedMessage,Link } from "gatsby-plugin-intl"


//const Footer = () => {
const Footer = ({ children, intl }) => {
  return (
    <footer>
      <small>&copy; 2020 Prof. Dr. med. Peter Michael Kövary
      &ensp;   
        <Link to="/legal/datenschutz/"> <FormattedMessage id="Impressum" defaultMessage="Impressum"/></Link> 
        &ensp;
        <Link to="/legal/impressum/"> <FormattedMessage id="Datenschutz" defaultMessage="Datenschutz"/></Link>
      </small>
    </footer>
  )
}

//export default Footer
export default injectIntl(Footer)