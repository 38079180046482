import React from "react"
import PropTypes from 'prop-types';
import Img from "gatsby-image"
import useSiteBannerImg from "./queries/useSiteBannerImg";
import { css } from "@emotion/core"
        // <Img fluid={banner_img.childImageSharp.fluid}  style={{ margin: '0.5rem', maxHeight: 'calc(30vh * 0.3)' }}  imgStyle={{ objectFit: 'contain' }}/> 




const Banner = ({ children }) => {

  const  banner_img  = useSiteBannerImg();

  return (

    <> 
        <h3 className="tw-text-xl tw-xl:tw-text-2xl tw-font-bold tw-text-green-800 tw-m-2 tw-text-center">
        Privatpraxis für Dermatologie, Allergologie und Lasertherapie
        </h3>

     
        <svg className="tw-h-24 tw-w-24 tw-fill-current tw-mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">        
          <path fill-opacity=".982" stroke="#000" stroke-width="8.595" d="M35.53 184.495l441.316-.617"/>
          <path fill="none" stroke="#000" stroke-width="7.188" d="M47.1 287.536c67.7-51.261 68.399-24.974 109.053-2.464 56.064 63.009 82.401-26.637 130.737-20.945 38.254 9.674 38.758 36.024 84.181 32.65m0 0c89.877-49.603 81.657-39.059 98.85-30.802"/>
          <path fill-opacity=".912" stroke="#000" stroke-linecap="round" stroke-opacity=".973" stroke-width="7.657" d="M140.12 426.458c50.745-118.197 194.25-313.973 207.265-343.13"/>
          <path fill-opacity=".912" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-opacity=".973" stroke-width="7.657" d="M135.203 447.652a9.885 9.856 0 01-13.888-1.393 9.885 9.856 0 011.355-13.853 9.885 9.856 0 0113.897 1.31 9.885 9.856 0 01-1.274 13.861"/>
        </svg>
        <h3 className="tw-text-xl tw-xl:tw-text-2xl tw-font-bold tw-text-green-800 tw-m-2 tw-text-center">
        Prof. Dr. med. Peter Michael Kövary</h3>
        
    </>

  );

};

Banner.propTypes = {

  children: PropTypes.node.isRequired,

};

export default Banner;