import React from "react"
import { css } from "@emotion/core"

//import { useStaticQuery,  graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import SkipToContent from "./skipToContent"
import Banner from "../components/banner"
import { injectIntl } from "gatsby-plugin-intl"




//export default ({ children }) => {
const Layout = ({ children, intl }) => {
  /*
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  */
  return (  
    <> 
    <div  >  
      <SkipToContent/>
      
    <div>
      <Banner />
    </div>

      <Header />
          <div > 
          
            <main id="main"  >
              {children} 
            </main>
          
          </div>
          
          
    </div>
    <Footer/>
    </>
  )
}
export default injectIntl(Layout)