import React from "react"
import styled from '@emotion/styled'
import { css } from "@emotion/core"



const SkipLinkKeyboardAccessible = styled.a`
        position: absolute;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(0 0 0 0);
        clip-path: inset(100%);
        white-space: nowrap;   
  }  
`

const SkipToContent = () => {
  return (
    <nav aria-label="Sprunglinks"  css={css`              
    height: 1 px
  `} >
    <p><SkipLinkKeyboardAccessible href="#main">Skip to main</SkipLinkKeyboardAccessible></p>                
    </nav>
    )
}

export default SkipToContent

